import React, { useEffect, useState, useRef } from 'react';
import Template from '../Template';
import {
    Breadcrumb,
    BreadcrumbEllipsis,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from "../../@/components/ui/breadcrumb";

import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import SyntaxHighlighter from 'react-syntax-highlighter';

import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "../../@/components/ui/tabs";
import Count from '../../@/animation/counter';

import { ScrollArea } from '../../@/components/ui/scroll-area';

export default function CountPage() {
    const codeString = `import Count from '@/animation/counter';

const App = () => (
    <div className="px-4 py-24 border rounded-sm flex items-center justify-center">
        <Count number={1000} duration={3} className="text-3xl font-bold" />
    </div>
);

export default App;`;


    const counterComponentCode = `import React, { useEffect, useState, useRef } from 'react';
import { useInView } from './lib/utils';

interface CountProps {
  className?: string;
  number: number;
  duration: number;
}

const easeOutQuad = (t: number) => {
  return t * (2 - t);
};

const Count: React.FC<CountProps> = ({ number, duration, className }) => {
  const [count, setCount] = useState(0);
  const countRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(countRef, { threshold: 0.5 });

  useEffect(() => {
    if (!isInView) return;

    const end = number;
    const totalMsDuration = duration * 1000;
    const startTime = performance.now();

    const tick = (now: number) => {
      const elapsed = now - startTime;
      const progress = Math.min(elapsed / totalMsDuration, 1);
      const easedProgress = easeOutQuad(progress);

      setCount(Math.round(easedProgress * end));

      if (progress < 1) {
        requestAnimationFrame(tick);
      }
    };

    requestAnimationFrame(tick);

    return () => {
    };
  }, [isInView, number, duration]); 
  return (
    <h3 className={className} ref={countRef}>
      {count}
    </h3>
  );
};

export default Count;

`

    const [isVisible, setIsVisible] = useState(false);

    const handleToggle = () => {
        setIsVisible(!isVisible);
    };

    const props = [
        {
            name: 'className',
            type: 'string',
            defaultValue: 'undefined',
            description: 'An optional class name to apply to the component. This allows for custom styling.'
        },
        {
            name: 'number',
            type: 'number',
            defaultValue: '0',
            description: 'The final number to count up to. This is the target value that will be displayed.'
        },
        {
            name: 'duration',
            type: 'number',
            defaultValue: '1',
            description: 'The duration of the animation in seconds. This defines how long it will take to count up to the target number.'
        }
    ];

    return (
        <Template>
            <div className='mx-auto w-full min-w-0'>
                <Breadcrumb>
                    <BreadcrumbList>
                        <BreadcrumbItem>
                            <BreadcrumbLink>Docs</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <BreadcrumbLink className='text-foreground'>Count Component</BreadcrumbLink>
                        </BreadcrumbItem>
                    </BreadcrumbList>
                </Breadcrumb>
                <h1 className='scroll-m-20 mt-6 text-3xl font-bold tracking-tight'>Count Animation</h1>
                <p className='text-muted-foreground'>A component that animates a number count-up effect when it comes into view.</p>
                <Tabs defaultValue="preview" className="mt-6">
                    <TabsList className="grid grid-cols-2 w-[200px]">
                        <TabsTrigger value="preview">Preview</TabsTrigger>
                        <TabsTrigger value="code">Code</TabsTrigger>
                    </TabsList>
                    <TabsContent value="preview">
                        <div className="px-4 py-24 border rounded-sm flex items-center justify-center">
                            <Count number={1000} duration={3} className="text-3xl font-bold" />
                        </div>
                    </TabsContent>
                    <TabsContent value="code">
                        <div className='mt-8 scroll-m-20 bg-[#2b2b2b] rounded-sm p-1'>
                            <SyntaxHighlighter language='jsx' style={a11yDark}>
                                {codeString}
                            </SyntaxHighlighter>
                        </div>
                    </TabsContent>
                </Tabs>
                <Tabs defaultValue="cli" className="mt-6">
                    <TabsList className="grid grid-cols-2 w-[200px]">
                        <TabsTrigger value="cli">CLI</TabsTrigger>
                        <TabsTrigger value="manual">Manual</TabsTrigger>
                    </TabsList>
                    <TabsContent value="cli">
                        <div className='mt-8 scroll-m-20 bg-[#2b2b2b] rounded-sm p-1'>
                            <SyntaxHighlighter language="bash" style={a11yDark}>
                                npx noshmaster@latest add count
                            </SyntaxHighlighter>
                        </div>
                    </TabsContent>
                    <TabsContent value="manual">
                        <div className={`relative overflow-hidden p-4 bg-[#2b2b2b] rounded-lg shadow-md ${isVisible ? "h-auto" : "max-h-52"}`}>
                            <div className={`absolute flex ${isVisible ? "items-end": "items-center bg-gradient-to-b from-zinc-700/30 to-zinc-950/90"} justify-center p-2 inset-0`}>
                                <button
                                    onClick={handleToggle}
                                    className="px-4 z-50 py-2 bg-background text-foreground rounded transition-colors focus:outline-none hover:bg-background/90"
                                    >
                                    {isVisible ? 'Collapse' : 'Expand'}
                                </button>
                            </div>

                            {isVisible ? (
                                <ScrollArea className="h-[50rem] rounded-md">
                                    <div className="bg-[#2b2b2b] rounded-sm p-1">
                                        <SyntaxHighlighter language='jsx' style={a11yDark}>
                                            {counterComponentCode}
                                        </SyntaxHighlighter>
                                    </div>
                                </ScrollArea>

                            ): (
                                <div className="bg-[#2b2b2b] rounded-sm p-1">
                                    <SyntaxHighlighter language='jsx' style={a11yDark}>
                                        {counterComponentCode}
                                    </SyntaxHighlighter>
                                </div>
                            )}
                            
                        </div>
                    </TabsContent>
                </Tabs>
                <table className="mt-10 min-w-full bg-white border border-gray-200 rounded-lg overflow-hidden">
                    <thead className="bg-gray-100 border-b border-gray-200">
                        <tr>
                            <th className="px-6 py-3 text-left text-gray-600 font-medium">Name</th>
                            <th className="px-6 py-3 text-left text-gray-600 font-medium">Default Value</th>
                            <th className="px-6 py-3 text-left text-gray-600 font-medium">Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.map((prop, index) => (
                            <tr key={index} className="border-b border-gray-200">
                                <td className="px-6 py-4 text-gray-800">{prop.name}</td>
                                <td className="px-6 py-4 text-gray-800">{prop.defaultValue}</td>
                                <td className="px-6 py-4 text-gray-800">{prop.description}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Template>
    );
}
